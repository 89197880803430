.profile {
  &-container {
    display: flex;
    height: 73vh;
  }

  &-sidebar {
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
      background-color: $sidebar-icon-color; // $sidebar-bullet-color;
    }
    &::-webkit-scrollbar {
      width: 0.5em;
    }
  }

  &-sidebar-block {
    border: 1px solid $gray-lightest;

    border-radius: 8px;

    padding: 1rem;
    margin-bottom: 20px;
    margin-right: .5rem;
  }

  &-footer {
    background-color: $white;
    border-top: 2px solid $gray-lightest;
    z-index: 1;
    padding: 25px;
  }
}

.category-container {
  .category-item {
    //float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;

    width: 30%;
    white-space: pre;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &-lg {
      width: 45%;
    }
  }

  .categories-group {
    padding-left: 15px;
    padding-right: 15px;

    border-bottom: 1px solid $gray-lightest;
    margin-bottom: 1rem;

    &-title {
      width: 100%;
    }
  }
}

.heading-end {
  .category-item-selected {
    margin-right: 10px;
    text-align: center;
    border-radius: 10px;
    padding: 6px 10px;
  }
}
