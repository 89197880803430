.home-visits-container table {
  border-right: 1px solid #009fe3;
  border-bottom: 1px solid #009fe3;
}

.home-visits-container table tbody tr td {
  width: (100% / 7);
  border-left: 1px solid $primary;
  vertical-align: top;

  &.no-slots {
    background: repeating-linear-gradient(
      45deg,
      rgba($gray, 0.1),
      rgba($gray, 0.1) 10px,
      rgba($gray, 0.5) 10px,
      rgba($gray, 0.5) 12px
    );
  }
}
/*.home-visits-container table tbody tr td:nth-child(7) {
  border-right: 1px solid $primary;
}*/

.home-visit-header-numbers {
  padding: 0 8px;
  background-color: $white;
  color: $black;
  border-radius: 5px;
  font-weight: bold;
}

.homevisits-overview-message-danger {
  color: $danger;
}

.home-visit-overview-booking-btn {
  width: 100%;
  padding: 0 1rem;

  &.disabled {
    background-color: rgba($gray-dark, 0.4);
    border: none;
    border-color: rgba($gray-dark, 0.4);
    box-shadow: 0 2px 3px rgba($gray-dark, 0.45);
  }

  @media (max-width: 1200px) {
    .btn-label {
      left: 0;
      background: transparent;
    }
  }
}

.home-visit-appointment-block {
  color: $black;
  padding: 2px 8px;
  border-radius: 10px;
  margin-bottom: 5px;

  &__assigned {
    background-color: rgba($primary, 0.25);
  }
  &__unassigned {
    background-color: rgba($danger, 0.25);
  }
  &__conflict {
    background-color: rgba($warning, 0.5);
  }

  &__title {
    span,
    strong {
      float: none;
      @include media-breakpoint-up(xxl) {
        float: left;
      }
    }
  }

  &__content {
    text-align: left;
  }

  &__buttons {
    text-align: center;
    @include media-breakpoint-up(xl) {
      text-align: right;
    }
  }

  a {
    color: rgba($black, 0.3);
    margin-left: 5px;
  }
}

.home-visit-overview-client-block {
  background-color: $white;

  padding: 5px;

  &__title {
    text-align: center;
    padding: 10px 0;

    border-bottom: 1px solid rgba($primary, 0.5);
  }
}
.home-visit-overview-client-block__timestamp {
  padding: 5px 0;
  margin-top: 10px;
  border-bottom: 1px solid rgba($primary, 0.5);
}

.home-visit-overview-client-block__time {
  margin-bottom: 5px;
  overflow: auto;

  &-time {
    width: 100%;
    float: left;

    @include media-breakpoint-up(xxl) {
      width: auto;
      float: left;
      vertical-align: sub;
      //margin-right: 20px;
    }
  }

  &-numbers {
    width: 100%;

    @include media-breakpoint-up(xxl) {
      width: auto;
      vertical-align: sub;
    }
  }

  a {
    width: 100%;

    @include media-breakpoint-up(xxl) {
      width: auto;
      float: right;

      &.disabled {
        background-color: rgba($gray-dark, 0.4);
        border: none;
        box-shadow: 0 2px 3px rgba($gray-dark, 0.45);
      }
    }
  }
}


#homevisit-day-print {
  .header-period td {
    padding: 20px;

    border-top: 2px solid rgba($primary, 0.4);
    border-bottom: 2px solid rgba($primary, 0.4);

    font-size: 1.2em;

    strong {
      margin-left: 1rem;
    }
  }

  .appointments {
    &-item {
      width: 49%;
      float: left;
      padding: 10px 20px;

      border-radius: 50px;
      border: 2px solid $primary-lightest;

      margin: 5px;

      &__title {
        font-size: 1.1em;
        font-weight: 700;
        margin-right: 1rem;
      }
    }
  }

  .spacer {
    height: 50px;
  }

  .pagebreak {
    page-break-before: always;
  }
}