div.dataTables_wrapper div.dataTables_length select,
div.dataTables_wrapper div.dataTables_length input,
div.dataTables_wrapper .dataTables_length div.rdt,
div.dataTables_wrapper div.dataTables_customFilter select,
div.dataTables_wrapper div.dataTables_customFilter input,
div.dataTables_wrapper .dataTables_customFilter div.rdt {
    width: auto;
    display: inline-block;
}

div.dataTables_wrapper .dataTables_length div.rdt,
div.dataTables_wrapper .dataTables_customFilter div.rdt {
    margin-left: 0.5rem !important;
}