.notification {
    &-outline-info {
        border: 1px solid $info;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-outline-success {
        border: 1px solid $success;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-outline-danger {
        border: 1px solid $danger;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-outline-warning {
        border: 1px solid $warning;
        padding: 5px 10px;
        margin-top: 5px;
    }
}

.alert {
    &-default {
        color: $black;
        background-color: $gray-200;

        .alert-content {
            margin-top: .6rem;
            display: flex;

            div {
                min-width: 15%;
            }
        }
    }
}
